
export default
  name: 'heat-list'
  props:
    model: {type: String, default: 'race'}
    href: {type: String, required: true}
    races: {type: Array}
  data: ->
    items: []
    fields: []
    sortBy: 'personal.name_last'
    sortDesc: false
    loading: true
    totalRows: null
    perPage: 300
    currentPage: 1
    filter: null
    filterable: {}
    filterOn: []
  computed:
    tableFields: ->
      field_options = {sortable: true}
      @fields.unshift({key: 'race_name', label: 'Дисциплина', type: 'select', options: @races}) if 'event' == @model
      @fields.unshift({key: '_number', label: '', title: 'Номер заявки', sortable: false})

      @fields.map (field) -> Object.assign({}, field, field_options)

    selectableFields: -> @fields.filter((field) -> 'select' == field.type)

    filterMask: ->
      fmask = 0b000
      fmask |= 0b001 if @filterable._text?
      @selectableFields.reduce(((mask, elem, index) =>
          mask |= Math.pow(2,index + 1) if @filterable[elem.key]?
          mask
        ), fmask)
    filtered: ->
      if 0 < @filterMask
        @filterable
      else
        null
  mounted: ->
    @getItems()
  methods:
    filterFunction: (row, filter) ->
      filteredRow = 0b000
      filteredRow |= 0b001 if filter._text? && Object.values(row).reduce(((res, rowValue) =>
        res || (rowValue? && rowValue.toString().toLowerCase().includes(filter._text.toString().toLowerCase()))
      ), false)
      filteredRow = @selectableFields.reduce(((mask, elem, index) =>
        mask |= Math.pow(2, index + 1) if @filterable[elem.key]? && row[elem.key] == @filterable[elem.key]
        mask
      ), filteredRow)

      (@filterMask & filteredRow) == @filterMask

    onFiltered: (filteredItems) ->
      @totalRows = filteredItems.length
      @currentPage = 1
    getItems: ->
      @axios.get("#{API_ROOT}#{@href}")
        .then (res) =>
          @items = Object.assign([], res.data.data.attributes.heats)
          fields = Object.assign([], res.data.data.attributes.form_fields)
          # Sort the fields by sort_order
          fields.sort((a, b) -> a.sort_order - b.sort_order)
          @fields = fields
          @totalRows = @items.length
        .catch (e) => console.error(e)
        .then () => @loading = false

